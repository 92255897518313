import './index.css';
import './App.css';
import * as styles from './Home.module.css'
import { Helmet } from "react-helmet"

import fns_logo from '../images/FR.png'
import fns_cover from '../images/fns_hoodie/fns_hoodie_1.png';
import fns_title from '../images/fns_hoodie/fns_title.png';

import fns_hoodie_back from '../images/fns_hoodie/fns_hoodie_back-min.png';
import fns_hoodie_front from '../images/fns_hoodie/fns_hoodie_front-min.png';

import React, { Component } from "react";
import Nav from '../components/Nav';
import Checkout from '../components/Checkout';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import gsap from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";

import { _addProduct, _productChange } from './cart'

export default class App extends Component {
  constructor() {
    super();

    this.max_quantity = 5

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitNav = this.handleSubmitNav.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.form = React.createRef();
    this.orders = React.createRef();
    this.display = React.createRef();

    this.productChange = this.productChange.bind(this);

    /* 
    PNG export 50% -> 1500 x 1500 -> https://tinypng.com/
    */
    this.fnshoodie = {
      id: 'hoodie_0001',
      pushid: 'fr_0001',
      name: 'Pullover Hoodie',
      desc: 'Finesse Responsibly',
      variants: [
        {
          name: 'Finesse Responsibly',
          img: fns_hoodie_back
        },
        {
          name: 'Finesse Responsibly',
          img: fns_hoodie_front
        },
      ],
      sizes: ['Extra Small', 'Small', 'Medium', 'Large', 'Extra Large']
    }

    this.inventory = [
      this.fnshoodie,
    ]


    /* init cart */
    var products = {}
    for (let product = 0; product < this.inventory.length; product++) {
      products[this.inventory[product].id] = {
        id: this.inventory[product].id,
        pushid: this.inventory[product].pushid,
        name: this.inventory[product].name,
        desc: this.inventory[product].desc,
        quantity: 0,
        size: this.inventory[product].sizes[0],
        variant: this.inventory[product].variants[0].name,
      }
    }

    this.state = {
      active: false,
      isCheckout: false,
      orders: [],
      products,
      productsDisplay: '',
      iterMid: 0,
      iterTop: 0,
      iterMS: 1000,
      bgcolor: "#000000",
      fgcolor: '#000000',
    }
  }

  isTopPage() {
    return (window.scrollY < 100);
  }

  nextColor() {

    //if(this.isTopPage())
    return "#000000"

    //const newC = (this.state.iterTop > this.color.length) ? 0 : this.state.iterTop + 1

    //this.setState({ iterTop: newC });

    //return this.color[newC];


    //stick for now
    //return "#ffbdbd"


  }

  nextFgColor() {


    return "#FFFFFF";
    /*
    if (this.isBottomPage())
      return "#FFFFFF";

    else 
    */
  }

  colorSwatch = () => {
    this.setState({ bgcolor: this.nextColor(), fgcolor: this.nextFgColor() })
  }


  color = [
    '#FF0000',
    '#FF0000',
    '#FF0000',
    '#FF0000',
    '#FF0000',
    '#FF0000',
    '#FF0000',
    '#FF0000',
    '#FF0000',
    '#FF0000',
    '#FF0000',
    '#FF0000',
    '#FF0000',
    '#FF0000',
    '#FF0000',
    '#FF0000',
    '#000000',
    '#000000',
    '#000000',
    '#000000',
    '#000000',
    '#000000',
    '#000000',
    '#000000',
    '#000000',
    '#000000',
    '#000000',
    '#000000',
  ];


  handleSubmitNav = (event) => {
    this.handleSubmit();
    this.form.current.submit()
  }

  handleClear = (event) => {
    sessionStorage.clear()
    this.setState({ orders: [] })

  }


  handleSubmit = (event) => {

    var pushOrder = ''
    var pushDisplay = ''

    if (this.state.orders.length === 0) {
      alert("cart is empty")
      event.preventDefault()
      return;
    }
    else
      this.setState({ isCheckout: true })

    for (let i = 0; i < this.state.orders.length; i++) {
      pushOrder = this.state.orders[i].push + ", " + pushOrder;
      pushDisplay = this.state.orders[i].display + ", " + pushDisplay;
    }

    this.orders.current.value = pushOrder
    this.display.current.value = pushDisplay
  }

  productChange = (event, product) => {

    let products = this.state.products
    let orders = this.state.orders

    console.log(orders)

    products[product.date] = _productChange(event, product)

    this.setState({
      products
    })

    if (product.quantity > 0 && event.target?.value === "confirm") {

      let order = products[product.date] //unique cart items
      orders.push(order)
      window.sessionStorage.setItem('orders', JSON.stringify(orders))
      

      this.setState({
        orders: orders
      })
    }

  }


  componentDidMount() {
    setInterval(this.colorSwatch, this.state.iterMS);
    gsap.registerPlugin(ScrollTrigger)

    gsap.from("#fns_cover", {
      scale: 1.2
    });

    gsap.to("#fns_cover", {
      scrollTrigger: {
        trigger: "#section",
        start: "top top",
        scrub: 6, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
      },
      opacity: 0,
      yPercent: -20,
      scale: 0.1
    });

    gsap.from("#fns_logo", {
      scrollTrigger: {
        scrub: 4, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
      },
      rotation: -200,
      opacity: 0.2,
      yPercent: 60
    });

    gsap.to("#fns_logo", {
      scrollTrigger: {
        trigger: "#section",
        scrub: 6, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
      },
      rotation: 20,
      yPercent: -10,
    });

    const savedCart = window.sessionStorage.getItem('orders')
    if (savedCart != undefined) {
      this.setState({ orders: JSON.parse(savedCart) })
    }
  }

  componentWillUnmount() {
    clearInterval(this.colorSwatch);
  }

  render() {

    const style = {
      backgroundColor: this.state.bgcolor,
      color: this.state.fgcolor
    }
    return (<>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />

        <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300&display=swap" rel="stylesheet" />
      </Helmet>
      <div>
        <title>Finesse Responsibly | Cozy Club</title>
        <Nav onClick={this.handleSubmitNav} cart={this.state.orders.length} />
        <Checkout onClick={this.handleSubmitNav} cart={this.state.orders.length} />
        <div style={style} className={styles.container}>

          <main className={styles.main}>

            <img id="fns_cover" src={fns_cover}></img>

            <div id="section" className={styles.description}>
              {Array.from({ length: this.inventory.length }, (value, product) =>
                <div key={product} className={styles.spacer}>
                  <span className={styles.title} >
                    {this.inventory[product].name}
                  </span>
                  <br />
                  {this.inventory[product].desc}
                  <br />
                  <br />
                  <div onClick={() => this.setState({ active: true })}>
                    <div>
                      <Carousel autoPlay={!this.state.active} thumbWidth={120} onChange={e => this.productChange(e, this.state.products[this.inventory[product].id])} className={styles.clip} swipeable={false} animationHandler={"fade"} infiniteLoop={true} useKeyboardArrows={true}>
                        {Array.from({ length: this.inventory[product].variants.length }, (value, index) => <img key={index} src={this.inventory[product].variants[index].img} alt="" />)}
                      </Carousel>
                    </div>
                    <h4>Style:<br />{this.state.products[this.inventory[product].id].variant}<br />{this.state.products[this.inventory[product].id].size}<br />
                      <label>Quantity: </label><button value="down" onClick={e => this.productChange(e, this.state.products[this.inventory[product].id])} className={styles.card}>-</button>{this.state.products[this.inventory[product].id].quantity}<button value="up" onClick={e => this.productChange(e, this.state.products[this.inventory[product].id])} className={styles.card}>+</button><br />
                      <label>Size: </label><select value={this.state.products[this.inventory[product].id].size} onChange={e => this.productChange(e, this.state.products[this.inventory[product].id])}>
                        {Array.from({ length: this.inventory[product].sizes.length }, (value, index) => <option key={index} value={this.inventory[product].sizes[index]}> {this.inventory[product].sizes[index]}</option>)}
                      </select><br />
                      <button className={styles.card} value="confirm" onClick={e => this.productChange(e, this.state.products[this.inventory[product].id])}>Add to Cart</button>
                      <br />
                    </h4></div>
                </div>
              )}


              <center><form action="https://server.cozyclub.us/create-checkout-session" ref={this.form} onSubmit={this.handleSubmit} method="POST">
                <input type="hidden" name="orders" id="orders" ref={this.orders} />
                <input type="hidden" name="display" id="display" ref={this.display} />
                <div>
                  <button className={styles.card} id="btnSubmit">Checkout</button></div>
              </form>
                <div>
                  <button className={styles.card} onClick={this.handleClear}>Clear Cart</button></div>

                <div id="fns_logo">
                  <img className={styles.fns_title} src={fns_title}></img>

                </div>
              </center>
            </div>
          </main>


          <a
            href="https://instagram.com/cozierclothing"
          >
            <footer className={styles.footer}>
              Cozier Clothing LLC
            </footer>
          </a>

        </div>
      </div>
      </>
    );
  }
}